.onl-post img { width: 100% }
.onl-post .img-sm { width: 50% }
.onl-post .img-mid { width: 70% }
.onl-post .img-lg { width: 90% }

.onl-post strong { font-weight: bold; }

.onl-post h1 { 
  font-size: 3rem;
  padding: 30px 0;
  text-align: center;
}

.onl-post h2 { 
  font-size: 2rem;
  padding: 20px 0;
}
.onl-post h3 {
  font-size: 1.8rem;
  padding: 15px 0;
}
.onl-post h4 {
  font-size: 1.6rem;
  padding: 15px 0;
}
.onl-post h5 {
  font-size: 1.4rem;
  padding: 15px 0;
}
.onl-post h6 {
  font-size: 1.2rem;
  padding: 15px 0;
}

.onl-post p, li {
  font-size: 1rem;
  line-height: 1.8rem;
}


/** **************************************************************************************/
/* <pre><code>  */
/** **************************************************************************************/

.onl-post pre {
  /* background-color: #555 !important; */
  /* color: #202020 !important; */
  /* color: blue !important; */
  border: none;  
  overflow-x: auto;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  /* font-size: 8px;
  line-height: 1.2 */
}

.onl-post code { 
  /* font-size: 9px;  */
  line-height: 1.2 
}

/** **************************************************************************************/
/* Header Numbering  */
/** **************************************************************************************/
body {counter-reset : h2; }
.onl-post h2 {counter-reset : h3;}
.onl-post h3 {counter-reset : h4;}
.onl-post h4 {counter-reset : h5; }
.onl-post h5 {counter-reset : h6; }
.onl-post h2:before {
  content : counter(h2,decimal) ". ";
  counter-increment : h2;
}
.onl-post h3:before {
  content : counter(h2,decimal) "." counter(h3,decimal) ". ";
  counter-increment : h3;
}
.onl-post h4:before {
  content : counter(h2,decimal) "." counter(h3,decimal) "." counter(h4,decimal) ". ";
  counter-increment : h4;
}
.onl-post h5:before {
  content : counter(h2,decimal) "." counter(h3,decimal) "." counter(h4,decimal) "." counter(h5,decimal) ". ";
  counter-increment : h5;
}
.onl-post h6:before {
  content : counter(h2,decimal) "." counter(h3,decimal) "." counter(h4,decimal) "." counter(h5,decimal) "." counter(h6,decimal) ". ";
  counter-increment : h6;
}
.onl-post h2.nocount:before, h3.nocount:before, h4.nocount:before, h5.nocount:before, h6.nocount:before {
  content : "";
  counter-increment : none;
}
