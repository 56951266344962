body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',  sans-serif;
  background-color: #efeff6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table input:focus {
  outline: none;
  box-shadow: 0 0px 5px #555;
}

/* input[type=password]{
  font-family:"굴림";  
} */

/* @font-face {
  font-family: 'nanumSquare-regular';
  src: url(./fonts/nanum-woff/NanumSquareR.woff2)
}

@font-face {
  font-family: 'nanumSquare-light';
  src: url(./fonts/nanum-woff/NanumSquareL.woff2)
}

@font-face {
  font-family: 'nanumSquare-bold'; 
  src: url(./fonts/nanum-woff/NanumSquareB.woff2)
}

@font-face {
  font-family: 'nanumSquare-extra-bold';
  src: url(./fonts/nanum-woff/NanumSquareEB.woff2)
} */

