/** ************************************************************************
 * Styling Navigation Nav 
 * ************************************************************************/

/* NavBar의 높이 */
.navbar-nav {
  padding: 10px 0px;
}

/* Nav > a 텍스트 속성 */
.nav-link,
.navbar-text {
  color: #2b2b2b !important;
  font-weight: 400;
}

/* Nav > a hover 속성 */
.nav-link:hover,
.nav-link.active {
  color: #2b2b2ba0 !important;
}

/* Nav > dropdown box의 속성 */
.dropdown-menu {
  min-width: "12rem";
  /* background-color: #eef; */
}

.dropdown-item {
  color: #2b2b2b !important;
  font-weight: 400;
}

.dropdown-item:hover,
.dropdown-item.active {
  color: #2b2b2b80 !important;
  background-color: #fff !important;
}

/** ****************************
  테스트에서만 사용하고 있음. 
* *****************************/
.nav-tabs a {
  color: skyblue !important;
  text-decoration: none;
}

.nav-tabs a:hover {
  color: steelblue !important;
  text-decoration: none;
}

.nav-tabs a.active {
  color: steelblue !important;
  text-decoration: none;
}

@media screen and (min-width: 1080px) {
  /* .navbar {
    padding: .5rem 10rem !important;
  } */
}
